/* eslint-disable react/no-unstable-nested-components */
import { ResponsiveBar } from '@nivo/bar'
import PropTypes from 'prop-types'
import React from 'react'

// Utils
import colors from '../../utils/colors'

/**
 *
 * Highlights
 *
 */
const Highlights = ({ kpis }) => (
  <div className="text-white-light flex flex-col gap-4">
    <div className="grid flex-none grid-cols-2 grid-rows-5 gap-4 sm:grid-cols-2 sm:grid-rows-3 md:grid-cols-4 md:grid-rows-2">
      <div className="bg-midnight col-span-1 row-span-1 flex flex-col items-center justify-center rounded-lg px-3 pb-1 pt-2 sm:col-span-1 md:col-span-1 md:row-span-1">
        <span className="text-brownGray text-xs uppercase leading-4 tracking-[1.5px]">
          Total Active Repair Orders
        </span>
        <span className="text-lg font-semibold leading-8 tracking-[-1px]">
          {kpis?.numberOfOpenRos || 0}
        </span>
      </div>

      <div className="bg-midnight col-span-1 row-span-1 flex flex-col items-center justify-center rounded-lg px-3 pb-2 pt-3 sm:col-span-1 md:col-span-1 md:row-span-1">
        <span className="text-brownGray text-xs uppercase leading-4 tracking-[1.5px]">
          Total Active Amount
        </span>
        <span className="text-lg font-semibold leading-8 tracking-[-1px]">
          {Number(kpis?.totalUnresolvedClaimAmount || 0).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </span>
      </div>

      <div className="bg-midnight col-span-2 row-span-3 flex flex-col items-center rounded-lg p-4 sm:col-span-2 md:col-span-2 md:row-span-2">
        <span className="text-brownGray text-xs uppercase leading-4 tracking-[1.5px]">
          RO Age Histogram
        </span>

        <div className="h-full w-full md:h-32">
          <ResponsiveBar
            borderRadius="4px"
            data={kpis?.roAgeHistogram || []}
            keys={['age']}
            indexBy="days"
            margin={{ top: 5, right: 0, bottom: 20, left: 25 }}
            padding={0.4}
            indexScale={{ type: 'band', round: true }}
            valueScale={{ type: 'linear' }}
            colors={colors.gold.DEFAULT}
            enableLabel={false}
            layers={['axes', 'bars']}
            axisBottom={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
            }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              tickValues: 5,
              format: (e) => (Math.floor(e) === e ? e : ''),
            }}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fill: colors.brownGray.DEFAULT,
                    fontSize: '12px',
                    fontWeight: '500',
                  },
                },
              },
            }}
            tooltip={(point) => (
              <div
                style={{
                  padding: '4px 5px',
                  background: '#F0F0ED',
                  borderRadius: '8px',
                  border: '1px solid rgb(255, 255, 255)',
                  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 10px',
                  fontSize: '16px',
                  color: '#045485',
                }}
              >
                <span>RO Count: {point.value}</span>
              </div>
            )}
          />
        </div>
      </div>

      <div className="bg-midnight col-span-1 row-span-1 flex flex-col items-center justify-center rounded-lg px-3 pb-2 pt-3 sm:col-span-1 md:col-span-1 md:row-span-1">
        <span className="text-brownGray text-xs uppercase leading-4 tracking-[1.5px]">
          New in Last 7 Days
        </span>
        <span className="text-lg font-semibold leading-8 tracking-[-1px]">
          {kpis?.newInLast7Days || 0}
        </span>
      </div>

      <div className="bg-midnight col-span-1 row-span-1 flex flex-col items-center justify-center rounded-lg px-3 pb-2 pt-3 sm:col-span-1 md:col-span-1 md:row-span-1">
        <span className="text-brownGray text-xs uppercase leading-4 tracking-[1.5px]">
          Resolved in Last 7 Days
        </span>
        <span className="text-lg font-semibold leading-8 tracking-[-1px]">
          {kpis?.resolvedInLast7Days || 0}
        </span>
      </div>
      <div className="bg-midnight row-span-1 flex items-center justify-between gap-6 rounded-lg px-4 py-6 sm:col-span-1">
        <span className="text-brownGray text-xs uppercase leading-4 tracking-[1.5px]">
          Number of High Dollar
        </span>
        <span className="text-lg font-semibold leading-8 tracking-[-1px]">
          {kpis?.numberOfHighDollarClaims || 0}
        </span>
      </div>
      <div className="bg-midnight row-span-1 flex items-center justify-between gap-6 rounded-lg px-4 py-6 sm:col-span-1">
        <span className="text-brownGray text-xs uppercase leading-4 tracking-[1.5px]">
          No update in more than 5 days
        </span>
        <span className="text-lg font-semibold leading-8 tracking-[-1px]">
          {kpis?.noUpdateInMoreThan5Days || 0}
        </span>
      </div>
      <div className="bg-midnight row-span-1 flex items-center justify-between gap-6 rounded-lg px-4 py-6 sm:col-span-1">
        <span className="text-brownGray text-xs uppercase leading-4 tracking-[1.5px]">
          Aging out in next 7 days
        </span>
        <span className="text-lg font-semibold leading-8 tracking-[-1px]">
          {Number(kpis?.agingOutInNext7DaysAmount || 0).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </span>
      </div>
      <div className="bg-midnight row-span-1 flex items-center justify-between gap-6 rounded-lg px-4 py-6 sm:col-span-1">
        <span className="text-brownGray text-xs uppercase leading-4 tracking-[1.5px]">
          Aged Out
        </span>
        <span className="text-lg font-semibold leading-8 tracking-[-1px]">
          {Number(kpis?.agedOutRosAmount || 0).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </span>
      </div>
    </div>
  </div>
)

Highlights.propTypes = {
  kpis: PropTypes.object.isRequired,
}

export default Highlights
