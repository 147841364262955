import _ from 'lodash'
import React from 'react'
import { toast as displayToast } from 'react-hot-toast'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import { useImmer } from 'use-immer'
import { CheckCircleIcon } from '../components/CheckCircleIcon'

// Component
import { Alert } from '../components/Alert'
import {
  oneLowercaseLetterRegEx,
  oneNumberRegEx,
  oneSymbolRegEx,
  oneUppercaseLetterRegEx,
} from '../components/PasswordVerifier'

// Action Icons
import { ActionAddIcon } from '../components/ActionAddIcon'
import { ActionCodeIcon } from '../components/ActionCodeIcon'
import { ActionDollarIcon } from '../components/ActionDollarIcon'
import { ActionIdBadgeIcon } from '../components/ActionIdBadgeIcon'
import { ActionMemoCheckIcon } from '../components/ActionMemoCheckIcon'
import { ActionMileageIcon } from '../components/ActionMileageIcon'
import { ActionMoreIcon } from '../components/ActionMoreIcon'
import { ActionPaperCheckIcon } from '../components/ActionPaperCheckIcon'
import { ActionPaperIcon } from '../components/ActionPaperIcon'
import { ActionPersonIcon } from '../components/ActionPersonIcon'
import { ActionUploadIcon } from '../components/ActionUploadIcon'

const ERROR_DEFAULT = 'An error occurred. Please try again.'
const ERROR_NO_INTERNET = 'Request could not be made. Please check your internet connection.'
const ERROR_TIMEOUT = 'Request timed out. Please try again.'

export const createWithDoc = ({ envName = '', docFunction = () => {}, component = '' }) => {
  let createComponentWithDoc
  if (envName !== 'production') {
    createComponentWithDoc = docFunction(component) // eslint-disable-line global-require
  }
  return createComponentWithDoc || component
}

export const getErrorMessage = (err) => {
  // - Axios does not return a response object if the service cannot be reached
  if (err.code === 'ERR_NETWORK') {
    return ERROR_NO_INTERNET
  }

  if (err.code === 'ECONNABORTED' || err.code === 'ETIMEDOUT') {
    return ERROR_TIMEOUT
  }

  // - If we do have a response and data object, use the full error object
  if (err.response && err.response.data) {
    try {
      if (typeof err.response.data === 'string') {
        return err.response.data.startsWith('<!DOCTYPE html>') ? ERROR_DEFAULT : err.response.data
      }

      const errorStringOrObject = Object.values(err.response.data)[0]
      if (typeof errorStringOrObject === 'object') return errorStringOrObject[0]
      return errorStringOrObject
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Could not infer error message from error response object')
    }
  }

  // If a message has not been set, fallback to our default message
  return ERROR_DEFAULT
}

/**
 * Updates the state object with the updated field.
 * @param {object} state
 * @param {object} updatedField
 */
export const updateState = (state, updatedField) => ({
  ...state,
  ...updatedField,
})

/**
 * Updates an array stored in state.
 * - `add`: adds value to the array
 * - `remote`: removes the value at the index from the array
 * - `update`: updates the value at the index in the array
 * - `reset` and `filter`: returns the supplied value
 * - `clear`: removes all values from the array
 * @param {object} state
 * @param {object} updatedState
 */
export const updateArrayState = (state, { type, value, index }) => {
  switch (type) {
    case 'add':
      return [...state, value]
    case 'remove': {
      const updatedState = [...state]
      updatedState.splice(index, 1)
      return updatedState
    }
    case 'update': {
      const updatedState = [...state]
      updatedState[index] = value
      return updatedState
    }
    case 'reset':
    case 'filter':
      return value
    case 'clear':
      return []
    default:
      return state
  }
}

export const joinClassNames = (...classes) => classes.filter(Boolean).join(' ')

export const useImmerState = useImmer

export const toast = (message, type) =>
  displayToast.custom(
    (t) =>
      t.visible && (
        <Alert message={message} type={type} onClose={() => displayToast.dismiss(t.id)} />
      ),
    { id: message },
  )

export const stripHtmlTags = (html) => {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = html
  return tempDiv.textContent.trim() || tempDiv.innerText.trim()
}

export const normalizeHtml = (html) => {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = html

  // Extract meaningful text content while ignoring attributes
  return tempDiv.textContent.trim()
}

/**
 * Handles updating pagination based on the supplied parameters and functions.
 * @param {number} page
 * @param {number} currentPage
 * @param {number} perPage
 * @param {number} totalRows
 * @param {object} pages
 * @param {func} setCurrentPage
 * @param {func} request
 * @param {string} baseUrl
 * @param {string} filter
 */
export const handlePagination = async (
  page,
  currentPage,
  perPage,
  totalRows,
  pages,
  setCurrentPage,
  request,
  baseUrl,
  filter = null,
) => {
  // If the user is requesting the first page and we are not on the next page,
  // we need to get the very first page and not utilize `previous`.
  if (page === 1 && currentPage > 1) {
    await request(`${baseUrl}${perPage}&${filter ? `${filter}&` : ''}page=1`)
  }
  // If the user is requesting the last page and we are not on the previous page,
  // we need to get the very last page and not utilize `next`.
  else if (page > currentPage && page - currentPage > 1) {
    await request(
      `${baseUrl}${perPage}&${filter ? `${filter}&` : ''}page=${Math.ceil(totalRows / perPage)}`,
    )
  }
  // If the user is requesting the next page.
  else if (page > currentPage) {
    await request(pages.next)
  }
  // Otherwise, the user is requesting the previous page.
  else {
    await request(pages.previous)
  }

  setCurrentPage(page)
}

/**
 * Handles verifying the password against requirements.
 * @param {string} value
 * @returns {string} error if there is one to display
 */
export const verifyPassword = (value) => {
  if (!value || value.length < 8) return 'Please enter more than 8 characters'
  if (!oneUppercaseLetterRegEx.test(value)) return 'Please enter at least 1 uppercase letter'
  if (!oneLowercaseLetterRegEx.test(value)) return 'Please enter at least 1 lowercase letter'
  if (!oneNumberRegEx.test(value)) return 'Please enter at least 1 number'
  if (!oneSymbolRegEx.test(value)) return 'Please enter at least 1 symbol'
  return undefined
}

/**
 * Formats the specified `phoneNumber`.
 * @param string phoneNumber
 * @returns
 */
export const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    const cleaned = ('', phoneNumber).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

    if (match) {
      const intlCode = match[1] ? '+1 ' : ''
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
  }
  return ''
}

/**
 * Configures the icon for the specified `icon` string and `completed` status.
 * @param {string} icon
 * @param {boolean} completed
 * @returns icon component
 */
export const configureActionItemIcon = (icon, completed = false) => {
  switch (icon) {
    case 'paper-check':
      return (
        <ActionPaperCheckIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'code':
      return (
        <ActionCodeIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'upload':
      return (
        <ActionUploadIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'person':
      return (
        <ActionPersonIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'memo-check':
      return (
        <ActionMemoCheckIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'mileage':
      return (
        <ActionMileageIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'add':
      return (
        <ActionAddIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'paper':
      return (
        <ActionPaperIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'more':
      return (
        <ActionMoreIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'dollar':
      return (
        <ActionDollarIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'id-badge':
      return (
        <ActionIdBadgeIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    default:
      return null
  }
}

/**
 * Renders the line item.
 * @param {object} lineItem
 */
export const configureRepairOrderLineItem = (lineItem) =>
  lineItem.actions.length > 0 && (
    <li key={lineItem.id} className="flex flex-col gap-1">
      <p className="text-charcoal whitespace-nowrap pb-1 text-xs font-semibold uppercase">
        {lineItem.name}
      </p>

      {_.map(lineItem.actions, (a) => configureRepairOrderAction(a))}
    </li>
  )

/**
 * Renders the action item.
 * @param {object} action
 */
export const configureRepairOrderAction = (action) => (
  <li key={action.id} className="flex items-center">
    <div className="flex h-5 w-4 items-center overflow-hidden">
      {action.completed ? (
        <CheckCircleIcon className="h-4 w-4 text-green-700" />
      ) : (
        <div
          aria-hidden="true"
          className="border-brownGray-200 ml-[2px] h-3 w-3 rounded-full border-[1.25px]"
        />
      )}
    </div>

    <span className="text-charcoal-900 flex-1 pl-1">{action.name}</span>
  </li>
)

/**
 * Handles verifying the currency input value.
 * @param {object} e
 * @param {func} setError
 * @param {func} clearErrors
 * @returns rawValue
 */
export const verifyCurrencyInputValue = (e, setError, clearErrors) => {
  let rawValue = e.target.value

  const decimalCount = (rawValue.match(/\./g) || []).length
  const hasMultipleDecimals = decimalCount > 1

  if (hasMultipleDecimals) {
    setError('additionalDollarsHeldUp', {
      type: 'custom',
      message: 'Cannot have multiple decimals',
    })
    // Return without updating the value to prevent adding a second decimal
    return null
  }
  clearErrors('additionalDollarsHeldUp')

  // Remove non-numeric characters except for the decimal point
  rawValue = rawValue.replace(/[^0-9.]/g, '')

  // Do not automatically format to two decimal places unless user inputs them
  // Only convert to a number if the input is a valid number without unnecessary formatting
  const numericValue = parseFloat(rawValue)

  if (!Number.isNaN(numericValue) && rawValue.includes('.')) {
    // Preserve user input without forcing two decimal places unless they input them
    const decimalIndex = rawValue.indexOf('.')
    const decimalPart = rawValue.substring(decimalIndex + 1)

    if (decimalPart.length > 2) {
      // If more than two decimal places are entered, truncate to two decimal places
      rawValue = rawValue.substring(0, decimalIndex + 3)
    }
  }

  return rawValue
}
