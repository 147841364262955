import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

// Components
import { Modal } from '../../components/Modal'
import { TextInput } from '../../components/TextInput'

// Utils & Services
import { createLineItem, updateLineItem } from '../../services/lineItem.service'
import { toast, verifyCurrencyInputValue } from '../../utils/helpers'

const LineItemModal = ({
  isOpen,
  setIsOpen,
  dealerId,
  repairOrderId,
  lineItem,
  getUpdatedRepairOrder,
  defaultTechnician,
  closeModal,
}) => {
  // State
  const [loading, setLoading] = useState(false)

  const onCloseModal = () => {
    closeModal()
    reset({ lineName: '', claimAmount: '' })
  }

  const isEditMode = Boolean(lineItem)

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    control,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      lineName: '',
      claimAmount: '',
    },
  })

  const handleSuccess = (message) => {
    toast(message, 'success')
    onCloseModal()
    getUpdatedRepairOrder()
  }

  const handleError = (message) => {
    if (
      message.length > 0 &&
      message[0] === 'Line item with this Repair order and Line name already exists.'
    ) {
      setError('lineName', {
        type: 'manual',
        message: message[0],
      })
    } else {
      toast(message, 'error')
    }
  }

  useEffect(() => {
    if (isEditMode) {
      reset({
        lineName: lineItem.lineName,
        claimAmount: lineItem.claimAmount,
      })
    }
  }, [isEditMode, lineItem])

  const onSubmit = async (data) => {
    const formattedData = {
      ...data,
      lineName: data.lineName.toUpperCase(),
      claimAmount: parseFloat(data.claimAmount.replace(/[^0-9.]+/g, '')).toFixed(2),
    }

    if (isEditMode) {
      await updateLineItem(
        dealerId,
        repairOrderId,
        lineItem.id,
        formattedData,
        setLoading,
        handleError,
        handleSuccess,
      )
    } else {
      await createLineItem(
        dealerId,
        repairOrderId,
        { ...formattedData, technician: defaultTechnician },
        setLoading,
        handleError,
        handleSuccess,
      )
    }
  }

  return (
    <Modal
      open={isOpen}
      title={isEditMode ? 'Edit Line Item' : 'Add Line Item'}
      setOpen={setIsOpen}
      loading={loading}
      onClose={onCloseModal}
      content={
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="my-6 flex h-full w-full flex-row gap-4 pt-2"
        >
          <TextInput
            data-testid="lineItemName"
            error={errors.lineName && (errors.lineName.message || 'This field is required')}
            label="Line ID"
            id="lineName"
            name="lineName"
            placeholder=""
            required
            fullWidth
            inputStyles="uppercase"
            {...register('lineName', { required: true })}
          />

          <Controller
            name="claimAmount"
            control={control}
            rules={{
              required: 'This field is required',
            }}
            defaultValue=""
            render={({ field: { value, onChange, ...field } }) => (
              <TextInput
                data-testid="lineItemClaimAmount"
                error={errors.claimAmount && errors.claimAmount.message}
                label="Amount"
                id="claimAmount"
                placeholder=""
                fullWidth
                currency
                prefix="$"
                className="text-base"
                {...field}
                value={value}
                onChange={(e) => {
                  const updatedValue = verifyCurrencyInputValue(e, setError, clearErrors)

                  // Update the form state with the raw value without moving the cursor
                  if (updatedValue) onChange(updatedValue)
                }}
              />
            )}
          />
        </form>
      }
      actions={[
        {
          type: 'submit',
          label: 'Save',
          onClick: handleSubmit(async (data) => {
            onSubmit(data)
          }),
        },
        { type: 'cancel', label: 'Cancel', onClick: onCloseModal },
      ]}
    />
  )
}

export default LineItemModal

LineItemModal.defaultValues = {
  defaultTechnician: '',
}

LineItemModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  dealerId: PropTypes.string.isRequired,
  repairOrderId: PropTypes.string.isRequired,
  lineItem: PropTypes.object,
  getUpdatedRepairOrder: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  defaultTechnician: PropTypes.string,
}
